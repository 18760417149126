<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>
            <v-row
              v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="advertiserId"
                  :items="advertisers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Advertiser')"
                  :disabled="loadingFirst"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingFirst"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row
              v-if="$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="providerId"
                  :items="providers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Provider')"
                  :disabled="loadingFirst"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingFirst"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  ref="camp"
                  v-model="campaignId"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  :label="$t('CampaignFilter')"
                  :disabled="loadingCampaign"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingCampaign"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row
              v-if="!$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="isWeekly"
                  :items="dataPeriodFilter[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('DisplayType')"
                  :disabled="loadingFirst"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!$checkRole('PROVIDERS')">
      <v-col>
        <campaign-statistics-widgets
          :date-from="transferDateFrom"
          :date-to="transferDateTo"
          :campaign-id="transferCampaignId"
          :is-weekly="isWeeklyTransfer"
          :advertiser-id="transferAdveriserId"
          :is-video-campaign="isVideoCampaign"
        >
        </campaign-statistics-widgets>
      </v-col>
    </v-row>
    <v-row v-if="!$checkRole('PROVIDERS')">
      <v-col>
        <v-card class="w-full">
          <v-card-text>
            <campaign-statistics-chart
              :data="dataFromDb"
              :is-weekly="isWeeklyTransfer"
            >
            </campaign-statistics-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dataFromDb"
          item-key="date_string"
          class="elevation-1"
          :search="search"
          :loading="loading"
          :locale="$i18n.locale"
        >
          <template
            v-if="!$checkRule('hide_excel')"
            v-slot:top
          >
            <div class="row">
              <div class="col-8">
                <div class="container">
                  <v-btn
                    outlined
                    color="primary"
                    @click="exportToExcel()"
                  >
                    {{ $t('VtrReportElements.Download') }}
                  </v-btn>
                </div>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>
          <template
            v-else
            v-slot:top
          >
            <div class="row">
              <div class="col-12">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline, mdiAccessPoint, mdiMicrosoftExcel, mdiFileExcel } from '@mdi/js'
import qs from 'qs'
import * as XLSX from 'xlsx-js-style'
import DateTimePicker from '../Components/DateTimePicker.vue'
import CampaignStatisticsWidgets from './Components/CampaignStatisticsWidgetsComponent.vue'
import CampaignStatisticsChart from './Components/CampaignStatisticsChartComponent.vue'

export default {
  components: {
    DateTimePicker,
    CampaignStatisticsWidgets,
    CampaignStatisticsChart,
  },
  data() {
    return {
      orderBy: 'dateString',
      sortDesc: true,
      search: '',
      advertiserId: null,
      advertisers: [],
      transferAdveriserId: null,
      providers: [],
      providerId: null,
      showMessage: false,
      isWeekly: false,
      timeout: 5000,
      color: 'error',
      messageText: '',
      isWeeklyTransfer: false,
      isVideoCampaign: false,
      brandSaftyData: [],
      excelStatistics: [],
      widgetsData: [],
      dataFromDb: [],
      statistics: [],
      headers: [],
      betType: 0,
      transferCampaignId: null,
      campaigns: [],
      campaignId: Number(this.$route.params.campaign_id),
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      transferDateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      transferDateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      loadingFirst: false,
      loadingCampaign: false,
      icons: {
        mdiAccountOutline,
        mdiLockOutline,
        mdiAccessPoint,
        mdiMicrosoftExcel,
        mdiFileExcel,
      },
      dataPeriodFilter: {
        en: [
          {
            name: 'By days',
            key: false,
          },
          {
            name: 'By weeks',
            key: true,
          },
        ],
        ru: [
          {
            name: 'По дням',
            key: false,
          },
          {
            name: 'По неделям',
            key: true,
          },
        ],
      },
    }
  },

  watch: {
    advertiserId() {
      this.getCampaings(false)
    },
    providerId() {
      this.getCampaings(false)
    },
  },

  mounted() {
    this.firstLoad()

    // this.$root.$on('update_city', this.getData())
  },

  methods: {
    exportToExcel() {
      const wb = XLSX.utils.book_new()
      const rows = []

      const sl = XLSX.utils.json_to_sheet(rows)

      const coastCol = 'G'
      let viewabilityCol = 'H'
      let fraudCol = 'I'

      if (!this.$checkAbility('CAN_VIEW_COST')) {
        viewabilityCol = 'G'
        fraudCol = 'H'
      }

      // Рабочая область
      sl['!ref'] = 'A1:Z50'

      // соединение ячеек
      sl['!merges'] = [{ s: { r: 0, c: 10 }, e: { r: 0, c: 0 } }]

      // Ширина столбцов
      sl['!cols'] = [
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ]

      // верхняя шапка
      sl.A1 = {
        t: 's',
        v: `${this.$t('StatExcelHeader')}`,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, alignment: { horizontal: 'center' } },
      }

      // шапка таблицы
      sl.A2 = {
        t: 's',
        v: this.$t('Date'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.B2 = {
        t: 's',
        v: this.$t('Shows'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.C2 = {
        t: 's',
        v: this.$t('References'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      if (this.isVideoCampaign) {
        sl.D2 = {
          t: 's',
          v: this.$t('Views'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }
      sl.E2 = {
        t: 's',
        v: this.$t('CTR'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.F2 = {
        t: 's',
        v: this.$t('Сoverage'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      if (this.$checkAbility('CAN_VIEW_COST')) {
        sl[`${coastCol}2`] = {
          t: 's',
          v: this.$t('Coast'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }
      if (this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
        sl[`${viewabilityCol}2`] = {
          t: 's',
          v: this.$t('Viewability'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
        sl[`${fraudCol}2`] = {
          t: 's',
          v: this.$t('Fraud'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }

      // Заполняем таблицу через AoA

      let count = 3
      this.dataFromDb.forEach(element => {
        sl[`A${count}`] = {
          t: 'd',
          v: element.dateString,
        }
        sl[`B${count}`] = {
          t: 'n',
          v: element.views,
        }
        sl[`C${count}`] = {
          t: 'n',
          v: element.clicks,
        }
        if (this.isVideoCampaign) {
          sl[`D${count}`] = {
            t: 'n',
            v: element.shows,
          }
        }
        sl[`E${count}`] = {
          t: 'n',
          v: element.ctr,
        }
        sl[`F${count}`] = {
          t: 'n',
          v: element.uniqViews,
        }
        if (this.$checkAbility('CAN_VIEW_COST')) {
          sl[`${coastCol}${count}`] = {
            t: 'n',
            v: element.cost,
          }
        }
        if (this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
          sl[`${viewabilityCol}${count}`] = {
            t: 'n',
            v: element.viewability,
          }
          sl[`${fraudCol}${count}`] = {
            t: 'n',
            v: element.fraud,
          }
        }
        // eslint-disable-next-line no-plusplus
        count++
      })

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, sl, 'Sheet1')

      XLSX.writeFile(wb, `${this.campaignId} statistics.xlsx`)
    },
    firstLoad() {
      this.getAdvertisersOrProviders().then(() => {
        this.getCampaings(true).then(() => {
          this.getData()
        })
      })
    },
    getData() {
      if (!this.campaignId) {
        this.messageText = this.$t('EmptyCampaignId')
        this.showMessage = true

        return
      }

      const currentCampign = this.campaigns.find(e => e.id === this.campaignId)
      if (currentCampign.is_video || currentCampign.is_views_api) {
        this.isVideoCampaign = true
      }
      this.loading = true
      this.transferDateFrom = this.dateFrom
      this.transferDateTo = this.dateTo
      this.transferAdveriserId = this.advertiserId
      this.transferCampaignId = this.campaignId
      this.isWeeklyTransfer = this.isWeekly
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      let params = {}

      params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          userId: this.$getUserId(),
          campaignIds: this.campaignId,
          isWeek: this.isWeekly,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      if (this.$checkRole('PROVIDERS') && this.providerId) {
        params.params.providerIds = this.providerId
      }
      if (this.advertiserId) {
        params.params.advertiserId = this.advertiserId
      }

      // this.$http.get('/campaign-statistics/table_data', params)
      this.$http.get(`${this.$apiBaseURL}/campaigns/statistics`, params).then(response => {
        this.dataFromDb = response.data !== null ? response.data : []

        this.headers = []

        this.headers.push({ text: this.$t('Date'), value: 'dateString' })
        this.headers.push({ text: this.$t('Shows'), value: 'views' })
        this.headers.push({ text: this.$t('References'), value: 'clicks' })
        if (this.isVideoCampaign) {
          this.headers.push({ text: this.$t('Views'), value: 'shows' })
        }
        this.headers.push({ text: this.$t('CTR'), value: 'ctr' })
        this.headers.push({ text: this.$t('Сoverage'), value: 'uniqViews' })
        this.headers.push({ text: this.$t('RF'), value: 'rf' })

        if (this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
          this.headers.push({ text: this.$t('Viewability'), value: 'viewability' })
          this.headers.push({ text: this.$t('Fraud'), value: 'fraud' })
        }

        if (this.$checkAbility('CAN_VIEW_COST')) {
          this.headers.push({ text: this.$t('Coast'), value: 'cost' })
        }

        this.loading = false
      })
    },
    getAdvertisersOrProviders() {
      this.loadingFirst = true

      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
          .then(response => {
            this.advertisers = response.data
            if (this.firstLoad === true) {
              this.advertiserId = null
            }

            this.loadingFirst = false
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/providers`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.providers = response.data

          this.loadingFirst = false
        })
    },
    getCampaings(isFirstLoad) {
      this.loadingCampaign = true
      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
            params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
          })
          .then(response => {
            this.campaigns = response.data !== null ? response.data : []
            if (isFirstLoad) {
              console.log(this.campaignId)
              if (!this.campaignId) {
                if (this.campaigns.length > 0) {
                  this.campaignId = this.campaigns[0].id
                } else {
                  this.campaignId = null
                }
              }
            } else if (this.campaigns.length > 0) {
              this.campaignId = this.campaigns[0].id
            } else {
              this.campaignId = null
            }

            this.loadingCampaign = false
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            this.showMessage = true
            this.messageText = error
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), providerIds: this.providerId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []
          if (isFirstLoad) {
            if (!this.campaignId) {
              if (this.campaigns.length > 0) {
                this.campaignId = this.campaigns[0].id
              } else {
                this.campaignId = null
              }
            }
          } else if (this.campaigns.length > 0) {
            this.campaignId = this.campaigns[0].id
          } else {
            this.campaignId = null
          }

          this.loadingCampaign = false
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error
        })
    },
  },
}
</script>
