<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="image"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <div v-show="data.length === 0">
            <v-alert
              outlined
              color="warning"
              class="d-flex justify-center"
              height="250"
              text
            >
              {{ $t('NoPlotDataAlert') }}
            </v-alert>
          </div>
          <div v-show="data.length > 0">
            <vue-apex-charts
              :options="chartOptions"
              :series="series"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    isWeekly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      categories: [],
      ageData: {},
      sexData: {},
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          height: 200,
        },
      },
    }
  },
  watch: {
    data() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true

      const tempSeries = []
      const tempCategories = []
      if (this.data !== null) {
        const views = []
        const clicks = []
        const uniqViews = []
        this.data.forEach(element => {
          views.push(element.views != null ? element.views : 0)
          clicks.push(element.clicks != null ? element.clicks : 0)
          uniqViews.push(element.uniqViews != null ? element.uniqViews : 0)
          const date = element.dateString.split(' / ')
          if (date.length > 1) {
            tempCategories.push(`${date[0].substr(5, 10)} / ${date[1].substr(5, 10)}`)
          } else {
            tempCategories.push(date[0] != null ? `${date[0].substr(5, 10)}` : null)
          }
        })
        tempSeries.push({ name: this.$t('Shows'), data: views })
        tempSeries.push({ name: this.$t('References'), data: clicks })
        tempSeries.push({ name: this.$t('Сoverage'), data: uniqViews })
      }

      this.series = tempSeries
      this.chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          height: 400,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'smooth',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: tempCategories,
        },
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 0.4,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: ['#e0cffe', '#b992fe', '#ab7efd'],
      }
      this.loading = false
    },
  },
}
</script>
<style lang="css">
.apexcharts-toolbar {
  display: none;
}
</style>
