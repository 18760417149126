<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="table-heading, list-item-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <v-card>
            <v-card-title>
              <div>{{ $t('BrandSafty') }}</div>
            </v-card-title>
            <v-card-actions>
              <v-btn
                v-if="brandSaftyData.filterWordsLevelId > 1"
                icon
                color="success"
                @click="openDialog()"
              >
                <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-chip
                v-if="brandSaftyData.filterWordsLevelId > 1"
                color="success"
              >
                On
              </v-chip>
              <v-chip
                v-if="brandSaftyData.filterWordsLevelId < 2"
                color="error"
              >
                Off
              </v-chip>
            </v-card-actions>
            <v-card-text v-if="brandSaftyData.filterWordsLevelId > 1">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-progress-linear
                    class="mt-3"
                    height="15"
                    v-bind="attrs"
                    :color="brandSaftyData.filterWordsLevelId > 3 ? 'success' : 'warning'"
                    :value="brandSaftyData.filterWordsLevelId / 5 * 100"
                    v-on="on"
                  >
                    <strong>{{ brandSaftyData.filterWordsLevelId }}/5</strong>
                  </v-progress-linear>
                </template>
                <span>{{ $t('SaftyLevel' + brandSaftyData.filterWordsLevelId) }}</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="primary"
            :description="$t('ShowsDecsription')"
            :icon="icons.mdiEyeOutline"
            :stat-title="$t('Shows')"
            :statistics="widgetsData.views.period"
            :subtitle="widgetsData.views.total"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="warning"
            :description="$t('ReferencesDescription')"
            :icon="icons.mdiImport"
            :stat-title="$t('References')"
            :statistics="widgetsData.clicks.period"
            :subtitle="widgetsData.clicks.total"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="success"
            :description="$t('CTRDescription')"
            :icon="icons.mdiFlaskOutline"
            :stat-title="$t('CTR')"
            :statistics="widgetsData.ctr.period + '%'"
            :subtitle="widgetsData.ctr.total + '%'"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        v-if="widgetsData.pi.total > 0"
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="error"
            :description="$t('PIDescription')"
            :icon="icons.mdiCursorDefaultClick"
            :stat-title="$t('PI')"
            :statistics="widgetsData.pi.period + '%'"
            :subtitle="widgetsData.pi.total + '%'"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="info"
            :description="$t('CoverageDescription')"
            :icon="icons.mdiAccountGroup"
            :stat-title="$t('Сoverage')"
            :statistics="widgetsData.coverage.period"
            :subtitle="widgetsData.coverage.total"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="primary"
            :description="$t('RFDescription')"
            :icon="icons.mdiBullhorn"
            :stat-title="$t('RF')"
            :statistics="widgetsData.rf.period"
            :subtitle="widgetsData.rf.total"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        v-if="$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')"
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="warning"
            :description="$t('ViewabilityDescription')"
            :icon="icons.mdiAccountVoice"
            :stat-title="$t('Viewability')"
            :statistics="widgetsData.viewability.period"
            :subtitle="widgetsData.viewability.total"
          >
          </widget-card>
        </div>
      </v-col>
      <v-col
        v-if="$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')"
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="success"
            :description="$t('FraudDescription')"
            :icon="icons.mdiBugOutline"
            :stat-title="$t('Fraud')"
            :statistics="widgetsData.fraud.period"
            :subtitle="widgetsData.fraud.total"
          >
          </widget-card>
        </div>
      </v-col>
      <!-- <v-col
        v-if="isVideoCampaign"
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="info"
            :description="$t('ViewsDescription')"
            :icon="icons.mdiPlayCircleOutline"
            :stat-title="$t('Views')"
            :statistics="widgetsData.views.period"
            :subtitle="widgetsData.views.total"
          >
          </widget-card>
        </div>
      </v-col> -->
      <v-col
        v-if="$checkAbility('CAN_VIEW_COST')"
        cols="12"
        sm="12"
        md="2"
        lg="2"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <widget-card
            color="primary"
            :description="$t('CoastDescription')"
            :icon="icons.mdiCash"
            :stat-title="$t('Coast')"
            :statistics="widgetsData.coast.period"
            :subtitle="widgetsData.coast.total"
          >
          </widget-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="isDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('RestrictedWords') }}
        </v-card-title>

        <v-card-text>
          {{ $t('BrandSaftyDescription') }}
          <v-chip
            :color="brandSaftyData.filterWordsLevelId > 3 ? 'success' : 'warning'"
          >
            {{ $t('Level' + brandSaftyData.filterWordsLevelId) }}
          </v-chip>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="brandSaftyData.words"
            item-key="word"
            class="elevation-1"
            :search="search"
            :loading="loading"
            :locale="$i18n.locale"
          >
            <template v-slot:top>
              <div class="row">
                <div class="col-12">
                  <v-text-field
                    v-model="search"
                    :label="$t('LblSearch')"
                    class="mx-4"
                  ></v-text-field>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="isDialogVisible = false"
          >
            {{ $t('BtnClose') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiInformationOutline,
  mdiEyeOutline,
  mdiInformationVariant,
  mdiImport,
  mdiFlaskOutline,
  mdiCursorDefaultClick,
  mdiAccountGroup,
  mdiBullhorn,
  mdiAccountVoice,
  mdiBugOutline,
  mdiShuffleVariant,
  mdiPlayCircleOutline,
  mdiCash,
} from '@mdi/js'
import qs from 'qs'
import WidgetCard from './WidgetsComponents/WidgetCardComponent.vue'

export default {
  components: { WidgetCard },
  props: {
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    campaignId: {
      type: Number,
      default: 0,
    },
    adsvertiserId: {
      type: Number,
      default: 0,
    },
    isWeekly: {
      type: Boolean,
      default: false,
    },
    isVideoCampaign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiEyeOutline,
        mdiInformationVariant,
        mdiImport,
        mdiFlaskOutline,
        mdiCursorDefaultClick,
        mdiAccountGroup,
        mdiBullhorn,
        mdiAccountVoice,
        mdiBugOutline,
        mdiShuffleVariant,
        mdiPlayCircleOutline,
        mdiCash,
      },
      brandSaftyData: {},
      widgetsData: {
        shows: {
          period: 0,
          total: 0,
        },
        clicks: {
          period: 0,
          total: 0,
        },
        coverage: {
          period: 0,
          total: 0,
        },
        ctr: {
          period: 0.0,
          total: 0.0,
        },
        rf: {
          period: 0.0,
          total: 0.0,
        },
        viewability: {
          period: 0.0,
          total: 0.0,
        },
        fraud: {
          period: 0.0,
          total: 0.0,
        },
        pi: {
          period: 0.0,
          total: 0.0,
        },
        views: {
          period: 0,
          total: 0,
        },
        coast: {
          period: 0.0,
          total: 0.0,
        },
      },
      isDialogVisible: false,
      search: '',
      loading: true,
    }
  },
  computed: {
    headers() {
      return [{ text: this.$t('Words'), value: 'word' }]
    },
  },
  watch: {
    dateFrom() {
      this.getData()
    },
    dateTo() {
      this.getData()
    },
    campaignId() {
      this.getData()
    },
    advertiserId() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },
  methods: {
    openDialog() {
      this.isDialogVisible = true
    },
    getData() {
      this.loading = true
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const paramsPeriod = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          campaignIds: this.campaignId,
          userId: this.$getUserId(),
          isWeek: this.isWeekly,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      const paramsAll = {
        params: {
          dateFrom: '1970-01-01',
          dateTo: `${new Date().toISOString().substr(0, 10)}`,
          campaignIds: this.campaignId,
          userId: this.$getUserId(),
          isWeek: this.isWeekly,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      if (this.advertiserId) {
        paramsPeriod.params.advertiserId = this.advertiserId
        paramsAll.params.advertiserId = this.advertiserId
      }

      // eslint-disable-next-line use-isnan
      if (this.campaignId) {
        this.$http
          .get(`${this.$apiBaseURL}/campaigns/statistics/brand/safety`, {
            params: { userId: this.$getUserId(), campaignIds: this.campaignId },
          })
          .then(response => {
            // this.brandSaftyData = response.data
            const tempData = response.data[0]
            this.brandSaftyData.filterWordsLevelId = tempData.filterWordsLevelId
            this.brandSaftyData.words = []
            const words = tempData.words !== null ? tempData.words.split(';') : []
            words.forEach(element => {
              this.brandSaftyData.words.push({ word: element })
            })
          })

        this.$http
          .get(`${this.$apiBaseURL}/campaigns/statistics/total`, paramsAll)
          .then(response => {
            const tempDataTotal = response.data
            if (tempDataTotal) {
              this.widgetsData.views.total = tempDataTotal[0].views
              this.widgetsData.clicks.total = tempDataTotal[0].clicks
              this.widgetsData.coverage.total = tempDataTotal[0].uniqViews
              this.widgetsData.ctr.total = tempDataTotal[0].ctr
              this.widgetsData.shows.total = tempDataTotal[0].shows
              this.widgetsData.rf.total = tempDataTotal[0].rf
              this.widgetsData.viewability.total = tempDataTotal[0].viewability
              this.widgetsData.fraud.total = tempDataTotal[0].fraud
              this.widgetsData.coast.total = tempDataTotal[0].cost
              this.widgetsData.pi.total = tempDataTotal[0].pi
              this.widgetsData.views.total = tempDataTotal[0].views
            }
          })
          .then(() => {
            this.$http.get(`${this.$apiBaseURL}/campaigns/statistics/total`, paramsPeriod).then(response => {
              const tempDataPeriod = response.data
              this.loading = false
              if (tempDataPeriod) {
                this.widgetsData.views.period = tempDataPeriod[0].views
                this.widgetsData.clicks.period = tempDataPeriod[0].clicks
                this.widgetsData.coverage.period = tempDataPeriod[0].uniqViews
                this.widgetsData.ctr.period = tempDataPeriod[0].ctr
                this.widgetsData.shows.period = tempDataPeriod[0].shows
                this.widgetsData.rf.period = tempDataPeriod[0].rf
                this.widgetsData.viewability.period = tempDataPeriod[0].viewability
                this.widgetsData.fraud.period = tempDataPeriod[0].fraud
                this.widgetsData.coast.period = tempDataPeriod[0].cost
                this.widgetsData.pi.period = tempDataPeriod[0].pi
                this.widgetsData.views.period = tempDataPeriod[0].views
              }
            })
          })
      }
    },
  },
}
</script>
<style lang="css">
.apexcharts-toolbar {
  display: none;
}
</style>
